import './bootstrap';
import '../css/app.css';
import Aura from '@primevue/themes/aura';
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createInertiaApp } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';
import Highcharts from 'highcharts';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { createApp, h } from 'vue';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { AppFAIcons } from './Config/Icons';
import 'highcharts/highcharts-more';
import { definePreset } from '@primevue/themes';
import PrimeVue from 'primevue/config';
import '@inertiaui/table-vue/style.css';

faLibrary.add(...AppFAIcons);

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
    numericSymbols: ['K', 'M', 'G', 'T', 'P', 'E'],
  },
  accessibility: {
    enabled: false,
  },
});

const appName = import.meta.env.VITE_APP_NAME || 'FranchiseSEE';

const MyPrimeVuePreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{sky.50}',
      100: '{sky.100}',
      200: '{sky.200}',
      300: '{sky.300}',
      400: '{sky.400}',
      500: '{sky.500}',
      600: '{sky.600}',
      700: '{sky.700}',
      800: '{sky.800}',
      900: '{sky.900}',
      950: '{sky.950}',
    },
  },
});

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      environment: import.meta.env.APP_ENV,
    });

    app
      .use(plugin)
      .use(ToastService)
      .use(PrimeVue, {
        ripple: true,
        theme: {
          preset: MyPrimeVuePreset,
          options: {
            darkModeSelector: false || 'none',
          },
        },
      })
      .use(ZiggyVue)
      .directive('tooltip', Tooltip)
      .component('font-awesome-icon', FontAwesomeIcon)
      .mount(el);

    return app;
  },
  progress: {
    color: '#4B5563',
  },
});
